import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {LanguageDetectorService} from '../../locale/language-detector/language-detector.service';
import {BlobResponse} from './type/blob-response';
import {XstitchApiGatewayUnmanagedException} from '../exception/xstitch-api-gateway-unmanaged-exception';

@Injectable({
   providedIn: 'root'
})
export class XstitchApiGatewayService {
   constructor(
      private httpClient: HttpClient,
      private languageDetector: LanguageDetectorService
   ) {
   }

   private static addXdebugToEndpoint(endpoint: string): string {

      if (environment.production === true) {
         return endpoint;
      }

      const sessionStart = 'XDEBUG_SESSION_START=';

      if (endpoint.includes('?')) {
         return endpoint + '&' + sessionStart;
      }

      return endpoint + '?' + sessionStart;
   }

   private static getFilenameFromResponseHeaders(headers: HttpHeaders, defaultFileName: string): string {
      const contentDispositionHeader = headers.get('Content-Disposition');

      if (contentDispositionHeader === null) {
         return defaultFileName;
      }

      const matches = contentDispositionHeader.match(/filename="?([^"]+)"?/);
      if (matches && matches.length > 1) {
         return matches[1];
      }
      return defaultFileName;
   }

   /**
    * @deprecated
    * @see XstitchApiGatewayService.getAsJsonRelativeRoute
    */
   public getDeprecated(relativeEndpoint: string): Observable<object> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;

      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);

      const headers = this._configureStandardHeaders();

      return new Observable<object>((subscriber: Subscriber<object>) => {
         this.httpClient.get(fullEndpoint, {observe: 'response', headers, withCredentials: true}).subscribe((r: HttpResponse<any>) => {

            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next(r);
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   public postWithJsonBodyRelativeRoute<T>(relativeEndpoint: string, data: Map<string, any>): Observable<T> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;
      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);
      const headers = this._configureStandardHeaders();
      headers.append('Content-type', 'application/json');
      const s = this._mapToJsonEncode(data);

      return new Observable<T>((subscriber: Subscriber<T>) => {
         const request = this.httpClient.post(fullEndpoint, s, {observe: 'response', headers, withCredentials: true});
         request.subscribe((r: HttpResponse<any>) => {
            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next(r.body as T);
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   public putWithJsonBodyRelativeRoute<T>(relativeEndpoint: string, data: Map<string, any>): Observable<T> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;
      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);
      const headers = this._configureStandardHeaders();
      headers.append('Content-type', 'application/json');
      const s = this._mapToJsonEncode(data);

      return new Observable<T>((subscriber: Subscriber<T>) => {
         const request = this.httpClient.put(fullEndpoint, s, {observe: 'response', headers, withCredentials: true});
         request.subscribe((r: HttpResponse<any>) => {
            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next(r.body as T);
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   public getAsJsonAbsoluteRoute<T>(absoluteEndpoint: string): Observable<T> {
      const baseRoute = environment.xstitch_api_url_base;

      if (!absoluteEndpoint.startsWith(baseRoute)) {
         throw new XstitchApiGatewayUnmanagedException('Backend endpoint absolute route should begin with ' + baseRoute);
      }

      const absoluteEndpoint2 = XstitchApiGatewayService.addXdebugToEndpoint(absoluteEndpoint);

      const headers = this._configureStandardHeaders();

      return new Observable<T>((subscriber: Subscriber<T>) => {

         const request = this.httpClient.get<T>(absoluteEndpoint2, {observe: 'response', headers, withCredentials: true});

         request.subscribe((r: HttpResponse<T>) => {

            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next(r.body as T);
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   public getAsJsonRelativeRoute<T>(relativeEndpoint: string): Observable<T> {
      const fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;

      return this.getAsJsonAbsoluteRoute(fullEndpoint);
   }

   public deleteRelativeRoute(relativeEndpoint: string): Observable<void> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;
      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);
      const headers = this._configureStandardHeaders();

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const request = this.httpClient.delete(fullEndpoint, {observe: 'response', headers, withCredentials: true});
         request.subscribe((r: HttpResponse<any>) => {
            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next();
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   public getAsBlobRelativeRoute(relativeEndpoint: string, defaultFileName: string): Observable<BlobResponse> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;
      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);

      const headers = this._configureStandardHeaders();

      return new Observable<BlobResponse>((subscriber: Subscriber<BlobResponse>) => {

         const request = this.httpClient.get(fullEndpoint, {
            responseType: 'blob',
            observe: 'response',
            headers, withCredentials: true
         });

         request.subscribe((response: HttpResponse<Blob>) => {

            this._resetSessionCookieFromResponse(response.headers);
            const blobName = XstitchApiGatewayService.getFilenameFromResponseHeaders(response.headers, defaultFileName);

            const blobContent = response.body;

            if (blobContent === null) {
               subscriber.error();
            } else {
               subscriber.next(new BlobResponse(blobContent, blobName));
            }
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   /**
    * @deprecated
    */
   public postWithJsonBodyDeprecated(relativeEndpoint: string, data: Map<string, any>): Observable<object> {
      let fullEndpoint = environment.xstitch_api_url_base + relativeEndpoint;
      fullEndpoint = XstitchApiGatewayService.addXdebugToEndpoint(fullEndpoint);
      const headers = this._configureStandardHeaders();
      headers.append('Content-type', 'application/json');
      const s = this._mapToJsonEncode(data);

      return new Observable<object>((subscriber: Subscriber<object>) => {
         this.httpClient.post(fullEndpoint, s, {observe: 'response', headers, withCredentials: true}).subscribe((r: HttpResponse<any>) => {
            this._resetSessionCookieFromResponse(r.headers);
            subscriber.next(r);
         }, (error: HttpErrorResponse) => {
            this._resetSessionCookieFromResponse(error.headers);
            subscriber.error(error);
         });
      });
   }

   _resetSessionCookieFromResponse(headers: HttpHeaders): void {
      const responseSessionId = headers.get(environment.server_session_name);

      if (!((responseSessionId === null) || (responseSessionId === undefined))) {
         window.localStorage.setItem(environment.server_session_name, responseSessionId);
      }
   }

   _mapToJsonEncode(map: Map<string, any>): string {
      const result = {};

      map.forEach((value, key) => {
         result[key] = value;
      });

      return JSON.stringify(result);
   }

   private _configureStandardHeaders(): HttpHeaders {
      const sessionId = window.localStorage.getItem(environment.server_session_name);

      let headers = new HttpHeaders();

      if ((sessionId !== undefined) && (sessionId !== null) && (sessionId.trim() !== '')) {
         headers = headers.append(environment.server_session_name, sessionId);
      }

      headers = headers.append('Accept-Language', this.languageDetector.getLanguage().getApiLiteral());

      return headers;
   }
}
