import {TagDetails} from './tag.details';
import {TagWithChildrenInterface} from '../interface/tag-details.interface';

export class TagDetailsWithChildren extends TagDetails {
   constructor(
      protected tagId: number,
      protected english: string,
      protected spanish: string,
      protected french: string,
      protected imgUrl: string,
      protected countProjectsWithSameTag: number,
      protected cssSubclass: string,
      private children: TagDetailsWithChildren[]
   ) {
      super(
         tagId,
         english,
         spanish,
         french,
         imgUrl,
         countProjectsWithSameTag,
         cssSubclass
      );
   }

   static constructFromApi(i: TagWithChildrenInterface): TagDetailsWithChildren {
      const chld: TagDetailsWithChildren[] = [];
      i.children.forEach((c: TagWithChildrenInterface) => {
         chld.push(TagDetailsWithChildren.constructFromApi(c));
      });


      return new TagDetailsWithChildren(
         i.id,
         i.descriptionEnglish,
         i.descriptionSpanish,
         i.descriptionFrench,
         i.tagImgHomepageLocation,
         i.projectsRelatedTag,
         i.cssSubclass,
         chld
      );
   }

   getChildren(): TagDetailsWithChildren[] {
      return this.children;
   }
}
