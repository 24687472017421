import {
   xstitchApiEndpoints,
   cookieLanguageName,
   xstitchApiErrorCodes,
   emailAddresses,
   continuousOnlineValidationRequestBufferMillis,
   queryStringParameterNames,
   frontEndRoutes,
   branding,
   xstitchApiEndpointPathParameters,
   frontEndRoutesPathParameters
} from './common';

export const environment = {
  production: false,
  xstitch_api_url_base: 'https://dev.xstitchlibrary.com/',
  xstitch_front_end_url_base: 'https://dev.xstitchlibrary.com/',
  xstitchApiEndpoints,
  xstitch_api_error_codes: xstitchApiErrorCodes,
  server_session_name: 'hAzNiM0Npv',
  cookie_language_name: cookieLanguageName,
  email_addresses_imported: emailAddresses,
  continuous_online_validation_request_buffer_millis: continuousOnlineValidationRequestBufferMillis,
  queryStringParameterNames,
  frontEndRoutes,
  branding,
  xstitchApiEndpointPathParameters,
  frontEndRoutesPathParameters
};
