import {Component, OnInit} from '@angular/core';
import {TagDetails} from './service/api-client/home-page/type/tag.details';
import {HomePageCategoryConfigurationObject} from './service/api-client/home-page/type/home-page-category-configuration.object';
import {HomePageService} from './service/api-client/home-page/home-page.service';
import {LanguageDetectorService} from './service/locale/language-detector/language-detector.service';
import {environment} from '../environments/environment';
import {LanguageFactory} from './service/locale/language-detector/helper/language-factory';
import {SetCanonicalLinkService} from './service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

   public mainCategories: TagDetails[] = [];

   title = 'frontend';

   constructor(
      private homePageService: HomePageService,
      private languageDetectorService: LanguageDetectorService,
      private setCanonicalService: SetCanonicalLinkService
   ) {
      const queryString = window.location.search;
      const parser = new URLSearchParams(queryString);
      const languageLiteral = parser.get(environment.queryStringParameterNames.languageLiteral);

      if (languageLiteral) {
         const language = LanguageFactory.createFromString(languageLiteral);

         this.languageDetectorService.setLanguage(language);
      }
   }

   ngOnInit(): void {
      this.setCanonicalService.setCanonicalUrlBasedOnCurrentRoute();
      this.homePageService.getHomePageCategoryConfiguration().subscribe((r: HomePageCategoryConfigurationObject) => {
         this.mainCategories = r.getCategories();
      });
   }
}
