import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {HomePageCategoryConfigurationObject} from './type/home-page-category-configuration.object';
import {environment} from '../../../../environments/environment';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {HttpResponse} from '@angular/common/http';

@Injectable({
   providedIn: 'root'
})
export class HomePageService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   getHomePageCategoryConfiguration(): Observable<HomePageCategoryConfigurationObject> {
      const endpoint = environment.xstitchApiEndpoints.homePage.homepageCategoryConfiguration;

      return new Observable<HomePageCategoryConfigurationObject>(
         (subscriber: Subscriber<HomePageCategoryConfigurationObject>) => {
            const response = this.xstitchApiGateway.getDeprecated(endpoint);

            return response.subscribe((observer2: HttpResponse<any>) => {
               subscriber.next(HomePageCategoryConfigurationObject.constructFromApi(observer2.body));
            }, error => {
               subscriber.error(error);
            });
         });
   }

   /**
    * This can be purely async
    */
   logHomePageVisit(): void {
      const endpoint = environment.xstitchApiEndpoints.events.homepageVisit;

      this.xstitchApiGateway.postWithJsonBodyDeprecated(endpoint, new Map<string, any>()).subscribe();
   }
}
