import {HomePageCategoryConfigurationInterface} from '../interface/home-page-category-configuration.interface';
import {TagDetails} from './tag.details';
import {TagDetailsInterface} from '../interface/tag-details.interface';

export class HomePageCategoryConfigurationObject {

   public constructor(private mainCategories: TagDetails[]) {
   }

   static constructFromApi(i: HomePageCategoryConfigurationInterface): HomePageCategoryConfigurationObject {

      const mainCategories: TagDetails[] = [];

      i.mainCategories.forEach((c: TagDetailsInterface) => {
         if (c.projectsRelatedTag !== 0) {
            mainCategories.push(TagDetails.constructFromApi(c));
         }
      });

      return new HomePageCategoryConfigurationObject(mainCategories);
   }

   getCategories(): TagDetails[] {
      return this.mainCategories;
   }
}
